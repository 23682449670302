import { HttpErrorMessage } from "@components/errors/HttpErrorMessage";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { getDefaultLayout } from "@lib/utils/layoutUtility";
import { Flex } from "@ui/components/layout/flex/Flex";
import type { ErrorPageProps } from "templates/ErrorPage";
import { generateGetStaticPropsForErrorPage } from "templates/ErrorPage";
import type { NextPageWithLayout } from "../interfaces/PageInterfaces";

const FourOhFourPage: NextPageWithLayout<ErrorPageProps> = () => {
    const { get: getMicrocopy } = useMicrocopy();

    return (
        <Flex>
            <div hidden aria-hidden="true" data-testid="http404" />
            <HttpErrorMessage
                headline={getMicrocopy(CommonCMS.shared, CommonCMS.error.headlineFourOhFour)}
                text={getMicrocopy(CommonCMS.shared, CommonCMS.error.textFourOhFour)}
                errorCode={404}
            />
        </Flex>
    );
};

export default FourOhFourPage;

FourOhFourPage.getLayout = getDefaultLayout;

export const getStaticProps = generateGetStaticPropsForErrorPage;
